import React from 'react'
import Template from './Template/Template'
import Home from './pages/Home'
import {BrowserRouter, Route, Switch} from 'react-router-dom';

document.title = "Deep Fragrance";



function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Template>
          <Route exact path='/' component={Home} />          
        </Template>
      </Switch>
    </BrowserRouter>
  )
}

export default App