import React from 'react'
import Navbar from './moduls/Navbar'
import Wrapper from './moduls/wrapper'
import Footer from './moduls/Footer'

function Template(props) {

    return (
        <div id="main-template_div">
            {/* <Navbar /> */}
            <Wrapper>
                {props.children}
            </Wrapper>
            <Footer />
        </div>
    )
}

export default Template
