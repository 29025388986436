import React from 'react'
import Button from '@mui/material/Button';
import '../statics/css/home.css';
import '../statics/css/responsive.css';
import logo from '../statics/assets/img/icon1.png';
import img_1 from '../statics/assets/img/img_1.png';
import { Divider } from '@mui/material';


function Home() {
  return (
    <div>

      <div className='mainHeaderBox'>
        <div className='titleSection'>
          <img src={logo} width={100} className="imageStyle" />
          <h1 style={{fontSize:35, marginTop: 10}}>Deep Fragrance</h1>
          <p style={{fontSize:30, marginTop: 10}}>Explore the most similar fragrances to your favourite fragrance out of more than 50,000 fragrances using AI Technology</p>
        </div>
      </div>

      <div className="mainImageContainer">
        <img src={img_1} className="wallImage" alt="Deep Fragrance" />
      </div>

        {/* <img src={logo} className="logo" alt="logo" /> */}

        <div className="contentContainer">

          <div style={{paddingTop:20, paddingBottom:20, width:"90%", margin:"0 auto"}}>
            {/* <Divider flexItem></Divider> */}

            <p className='pclass'>Deep Fragrance uses state-of-the-art Machine Learning and AI technology to find and recommend the most similar fragrance to any given fragrance by considering all the notes and accords of more than 50,000 fragrances/perfumes. </p>
            <p className='pclass'>To use this app, simply type the name of a perfume/fragrance like "Coco Chanel" and press the search button. First, top relevant search results will be displayed and then selecting a desired fragrance will take you to the recommendation page which an AI algorithm is used to find the most similar fragrances (ranked by the similarity score) searching through the detailed ingredients (notes/accords) of more than 50 thousands fragrances. The recommended fragrances should smell very similar to your searched fragrance.</p>

            <p className='pclass'>Try it and let us know what do you think!</p>
          </div>
        </div>


        <div className="pclass">
          <p>Email: deepfragrance.en@gmail.com</p>
        </div>


        <div className='copyright'>
          <p>Copyright @ 2022 Deep Fragrance. All rights reserved.</p>
        </div>

    </div>
  )
}

export default Home

